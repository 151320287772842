import React, { lazy, Suspense, useState, useEffect } from 'react'
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom'

import Navbar from './components/Navbar'
import Footer from './components/Footer'
import Preloader from './components/Preloader'
import ScrollToTop from './components/ScrollToTop'

import Home from './components/Home/Home'
import Info from './components/About/About'
import Proyectos from './components/Projects/Projects'
import Certificados from './components/Certificados/Certificados'
import Noticias from './components/News/News'
import Conexiones from './components/RRSS/RRSS'
import VoiceIt from './components/VoiceIt/VoiceIt'
import VoiceMe from './components/VoiceMe/VoiceMe'

const LM = lazy(() => import('./components/LM/LM'))

function App() {
  const [load, setLoad] = useState(true)

  useEffect(() => {
    const handleLoad = () => setLoad(false)
    const disableDefault = (e) => e.preventDefault()

    document.readyState === 'complete'
      ? handleLoad()
      : window.addEventListener('load', handleLoad)
    window.addEventListener('contextmenu', disableDefault)
    window.addEventListener('dragstart', disableDefault)

    return () => {
      window.removeEventListener('load', handleLoad)
      window.removeEventListener('contextmenu', disableDefault)
      window.removeEventListener('dragstart', disableDefault)
    }
  }, [])

  return (
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <Preloader load={load} />
      <div className="App">
        <Navbar />
        <ScrollToTop />
        <Suspense fallback={<div>Cargando...</div>}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/voiceme" element={<VoiceMe />} />
            <Route path="/proyectos" element={<Proyectos />} />
            <Route path="/certificados" element={<Certificados />} />
            <Route path="/info" element={<Info />} />
            <Route path="/noticias" element={<Noticias />} />
            <Route path="/conexiones" element={<Conexiones />} />
            <Route path="/voiceit" element={<VoiceIt />} />
            <Route path="/lm" element={<LM />} />
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </Suspense>
        <Footer />
      </div>
    </BrowserRouter>
  )
}

export default App
