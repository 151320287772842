import TW from '../Assets/tw.png'
import TT from '../Assets/tt.png'
import DC from '../Assets/dc.png'
import YT from '../Assets/yt.png'
import GH from '../Assets/gh.png'
import IG from '../Assets/ig.png'

import { useLocation } from 'react-router-dom'
import { Image, Container } from 'react-bootstrap'

const socialLinks = [
  { href: 'https://www.x.com/ImPavloh', img: TW, alt: 'Twitter' },
  {
    href: 'https://discord.com/users/471780464542351393',
    img: DC,
    alt: 'Discord',
  },
  { href: 'https://www.instagram.com/ImPavloh', img: IG, alt: 'Instagram' },
  { href: 'https://www.youtube.com/c/@Pavloh', img: YT, alt: 'YouTube' },
  { href: 'https://www.tiktok.com/@impavloh', img: TT, alt: 'TikTok' },
  { href: 'https://www.github.com/ImPavloh', img: GH, alt: 'GitHub' },
]

function Footer() {
  const location = useLocation()
  const shouldHideFooter = location.pathname === '/conexiones'
  const footerClass =
    location.pathname === '/voiceit' || location.pathname === '/mvl'
      ? 'default-cursor'
      : ''

  if (shouldHideFooter) {
    return null
  }

  return (
    <Container fluid className={`footer ${footerClass}`}>
      <div className="social-container">
        {socialLinks.map((link, index) => (
          <a
            key={`${index}-${link.href}`}
            href={link.href}
            target="_blank"
            rel="noopener noreferrer"
            aria-label={`Follow on ${link.alt}`}
            className="social-button"
          >
            <Image
              src={link.img}
              alt={link.alt}
              loading="lazy"
              crossOrigin="anonymous"
            />
          </a>
        ))}
      </div>
    </Container>
  )
}

export default Footer
