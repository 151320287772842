import React from 'react'
import Particle from '../Particle'
import { Image, Container, Row, Col } from 'react-bootstrap'
import AnimatedCursor from 'react-animated-cursor'
import ReactCompareImage from 'react-compare-image'
import { Helmet } from 'react-helmet'
import { isMobile } from 'react-device-detect'
import Typewriter from 'typewriter-effect'

import Pav from '../../Assets/photo1.webp'
import Pav2 from '../../Assets/avatar.webp'

const recycledJLink = 'http://recycledj.vercel.app'

const Home = () => {
  return (
    <>
      {!isMobile && (
        <AnimatedCursor
          innerSize={8}
          trailingSpeed={2}
          outerSize={20}
          outerAlpha={0}
          innerScale={0.7}
          outerScale={2}
          innerStyle={{
            boxShadow: '0px 0px 3px 2px rgba(0, 0, 0, 0.5)',
            backgroundColor: '#faedcd',
          }}
          outerStyle={{
            boxShadow: '0px 0px 5px 2px rgba(0, 0, 3, 0.5)',
            border: '3px solid #fefaf0',
          }}
          clickables={[
            'strong',
            'input[type="text"]',
            'input[type="email"]',
            'input[type="number"]',
            'input[type="submit"]',
            'input[type="image"]',
            'label[for]',
            'select',
            'textarea',
            'button',
            '.link',
            'a',
            '.image-3d',
          ]}
        />
      )}
      <div className="home-section">
        <Helmet>
          <title>Pavloh | Inicio</title>
          <meta property="og:url" content="https://pavloh.com" />
          <meta property="og:type" content="website" />
          <meta property="og:title" content="Pavloh" />
          <meta property="og:description" content="Pavloh" />
          <meta name="twitter:title" content="Pavloh" />
          <meta
            name="twitter:description"
            content="Página web de Pavloh ~ @impavloh Especializado en inteligencia artificial y desarrollo web."
          />
        </Helmet>
        <Particle />
        <Container className="home-content">
          <Row>
            <Col md={7} className="home-header">
              <h1 style={{ paddingBottom: 15 }} className="heading">
                Hey{' '}
                <span className="wave" role="img" aria-label="wave">
                  👋🏻
                </span>
              </h1>
              <h1 className="heading-name">
                Soy <strong className="main-name">Pavloh</strong>
              </h1>
              <div style={{ paddingLeft: 40, textAlign: 'left' }}>
                <Typewriter
                  className="typewriter"
                  options={{
                    strings: [
                      'Software developer',
                      'Content creator',
                      'Editor',
                    ],
                    autoStart: true,
                    loop: true,
                    deleteSpeed: 10,
                  }}
                />
              </div>
              <div className="voice-me-container">
                <div style={{ textAlign: 'center' }}>
                  <h2 style={{ color: '#000000' }}>Mi último proyecto</h2>
                  <h3 style={{ color: '#000000' }}>Web Recycled J</h3>
                  <p style={{ color: '#000000' }}>
                    Descubre la discografía completa, conciertos y merchandising
                    de Recycled J
                  </p>
                  {!isMobile && (
                    <Image
                      src="/recycledj/recycledj.jpeg"
                      alt="RecycledJ"
                      style={{
                        maxWidth: '100%',
                        margin: '5px',
                        borderRadius: 10,
                      }}
                    />
                  )}
                </div>
                <div className="buttonsVMDiv">
                  <a
                    href={recycledJLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <button className="button-secondary">
                      <span>Ir a la web</span>
                    </button>
                  </a>
                </div>
              </div>
            </Col>
            <Col
              md={5}
              className="axd2"
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
              }}
            >
              <div
                className="image-3d"
                style={{ width: '100%', height: '100%' }}
              >
                <ReactCompareImage
                  sliderPositionPercentage={0.28}
                  leftImage={Pav}
                  rightImage={Pav2}
                  skeleton={
                    <div style={{ width: '100%', height: '100%' }}>
                      Loading...
                    </div>
                  }
                  className={`img-fluid image-shadow image-rotate3d axd`}
                  handleSize="25"
                  hover={true}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default Home
