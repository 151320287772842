import React, { useState } from 'react'
import ProjectCard from './ProjectCards'
import Particle from '../Particle'
import VML from '../../Assets/vmlogo.webp'
import { BsGithub } from 'react-icons/bs'
import { CgWebsite } from 'react-icons/cg'
import { Form, Container, Row, Col } from 'react-bootstrap'
import { SiDiscord, SiSpigotmc, SiCondaforge } from 'react-icons/si'
import AnimatedCursor from 'react-animated-cursor'
import { Helmet } from 'react-helmet'
import { isMobile } from 'react-device-detect'

const typeFilterOptions = [
  { value: '', label: 'Tipo de proyecto' },
  { value: 'bot', label: 'Bot' },
  { value: 'web', label: 'Sitio web' },
  { value: 'extension', label: 'Extensión' },
  { value: 'plataforma', label: 'Plataforma' },
  { value: 'repositorio', label: 'Repositorio' },
]

const languageFilterOptions = [
  { value: '', label: 'Categoría de tecnología' },
  { value: 'NextJS', label: 'NextJS' },
  { value: 'React', label: 'React' },
  { value: 'HTML5', label: 'HTML5' },
  { value: 'CSS3', label: 'CSS3' },
  { value: 'Python', label: 'Python' },
  { value: 'Colab', label: 'Colab' },
  { value: 'Tensorflow', label: 'Tensorflow' },
  { value: 'Discord API', label: 'Discord API' },
  { value: 'Minecraft Mod', label: 'Minecraft Mod' },
  { value: 'Minecraft Plugin', label: 'Minecraft Plugin' },
]

const opensourceFilterOptions = [
  { value: 'true', label: 'Código abierto' },
  { value: 'false', label: 'Código privado' },
]

const projectData = [
  {
    types: ['web'],
    opensource: false,
    showLogo: false,
    imgPaths: [
      '/recycledj/recycledj.jpeg',
      '/recycledj/moviles.png',
      '/recycledj/previews.png',
      '/recycledj/musicaConciertos.png',
    ],
    title: 'Recycled J',
    titlePosition: 'top',
    titleMargin: '10px 0',
    description:
      'Web no oficial de Recycled J con discografía completa, detalles sobre conciertos, merchandising y galería de imágenes y videos oficiales.',
    descriptionPosition: 'bottom',
    descriptionMargin: '10px 0',
    imageStyle: { maxWidth: '100%', margin: '0 auto', objectFit: 'contain' },
    technologies: [
      {
        filter: 'NextJS',
        logo: 'https://img.shields.io/badge/next.js-000000?style=for-the-badge&logo=nextdotjs&logoColor=white',
      },
    ],
    buttons: [
      {
        text: 'Web',
        variant: 'secondary',
        icon: CgWebsite,
        link: 'http://recycledj.vercel.app',
        newTab: true,
      },
    ],
  },
  {
    types: ['web', 'repositorio'],
    opensource: true,
    showLogo: true,
    logoPath: '/spooklens/logo.png',
    imgPaths: [
      '/spooklens/preview.jpg',
      '/spooklens/modals.png',
      '/spooklens/socialhub.png',
      '/spooklens/store.png',
      '/spooklens/uploader.png',
    ],
    title: 'SpookLens',
    titlePosition: 'top',
    titleMargin: '10px 0',
    description:
      'Finalista Hackathon Cloudinary x MiduDev. Red social con temática de Halloween.',
    descriptionPosition: 'bottom',
    descriptionMargin: '10px 0',
    imageStyle: { maxWidth: '100%', margin: '0 auto', objectFit: 'contain' },
    technologies: [
      {
        filter: 'NextJS',
        logo: 'https://img.shields.io/badge/next.js-000000?style=for-the-badge&logo=nextdotjs&logoColor=white',
      },
    ],
    buttons: [
      {
        text: 'Web',
        variant: 'secondary',
        icon: CgWebsite,
        link: 'https://spooklens.vercel.app',
        newTab: true,
      },
      {
        text: 'Repositorio',
        variant: 'primary',
        icon: BsGithub,
        link: 'https://github.com/ImPavloh/spooklens',
        newTab: true,
      },
    ],
  },
  {
    types: ['web', 'repositorio'],
    opensource: true,
    showLogo: false,
    imgPaths: [
      '/arcanetflix/arcanemain.png',
      '/arcanetflix/arcanemobile.png',
      '/arcanetflix/arcanecapturas.png',
    ],
    title: 'ArcaNETFLIX',
    titlePosition: 'top',
    titleMargin: '10px 0',
    description:
      'Una web inspirada en la serie Arcane de Riot Games y Netflix. Incluye playlist oficial, selección de merch oficial, clips y capturas de la serie, y próximamente una sección de fanarts.',
    descriptionPosition: 'bottom',
    descriptionMargin: '10px 0',
    imageStyle: { maxWidth: '100%', margin: '0 auto', objectFit: 'contain' },
    technologies: [
      {
        filter: 'NextJS',
        logo: 'https://img.shields.io/badge/next.js-000000?style=for-the-badge&logo=nextdotjs&logoColor=white',
      },
    ],
    buttons: [
      {
        text: 'Web',
        variant: 'secondary',
        icon: CgWebsite,
        link: 'https://arcanetflix.vercel.app',
        newTab: true,
      },
      {
        text: 'Repositorio',
        variant: 'primary',
        icon: BsGithub,
        link: 'https://github.com/impavloh/arcanetflix',
        newTab: true,
      },
    ],
  },
  {
    types: ['web', 'extension'],
    opensource: false,
    showLogo: false,
    imgPaths: [
      '/chirptts/chirptts.png',
      '/chirptts/chatgpt.jpeg',
      '/chirptts/wikipedia.jpeg',
    ],
    title: 'ChirpTTS',
    titlePosition: 'top',
    titleMargin: '10px 0',
    description:
      'Una extensión de navegador gratuita que convierte tu texto en encantadores sonidos al estilo Animal Crossing. Perfecta para lecturas atractivas en sitios como ChatGPT y Wikipedia.',
    descriptionPosition: 'bottom',
    descriptionMargin: '10px 0',
    imageStyle: { maxWidth: '100%', margin: '0 auto', objectFit: 'contain' },
    technologies: [
      {
        filter: 'JavaScript',
        logo: 'https://img.shields.io/badge/JavaScript-F7DF1E?style=for-the-badge&logo=javascript&logoColor=black',
      },
    ],
    buttons: [
      {
        text: 'Web',
        variant: 'secondary',
        icon: CgWebsite,
        link: 'http://chirptts.vercel.app',
        newTab: true,
      },
    ],
  },
  {
    types: ['bot', 'web'],
    year: '2023',
    opensource: false,
    showLogo: true,
    logoPath: VML,
    imgPaths: ['https://i.imgur.com/NcMIajh.png'],
    title: 'VoiceMe!',
    titlePosition: 'top',
    titleMargin: '10px 0',
    description:
      'Bot TTS de Discord verificado con IA para usar voces personalizadas como Ibai, Homer Simpson, Ed Sheeran, Elon Musk y muchos más ~ repositorio privado',
    descriptionPosition: 'bottom',
    descriptionMargin: '10px 0',
    imageStyle: { maxWidth: '50%', margin: '0 auto', objectFit: 'contain' },
    logoMargin: '15px 0',
    technologies: [
      {
        filter: 'Tensorflow',
        logo: 'https://img.shields.io/badge/TensorFlow-FF6F00?style=for-the-badge&logo=tensorflow&logoColor=white',
      },
      {
        filter: 'Python',
        logo: 'https://img.shields.io/badge/Python-14354C?style=for-the-badge&logo=python&logoColor=white',
      },
      {
        filter: 'Discord API',
        logo: 'https://img.shields.io/badge/Discord-5865f2?style=for-the-badge&logo=discord&logoColor=white',
      },
    ],
    buttons: [
      {
        text: 'Web',
        variant: 'secondary',
        icon: CgWebsite,
        link: '/voiceme',
        newTab: false,
      },
      {
        text: 'Añadir a Discord',
        variant: 'primary',
        icon: SiDiscord,
        link: 'https://discord.com/oauth2/authorize?client_id=909811449499631646&permissions=277028588544&scope=bot',
        newTab: true,
      },
    ],
  },
  {
    types: ['web'],
    year: '2023',
    opensource: true,
    showLogo: true,
    logoPath:
      'https://raw.githubusercontent.com/ImPavloh/showdownclash/main/recursos/logo.png',
    imgPaths: [
      'https://i.imgur.com/fhBJdTx.png',
      'https://i.imgur.com/xikn4Uw.png',
      'https://i.imgur.com/DWHPglO.png',
      'https://i.imgur.com/DbNZj1J.png',
      'https://i.imgur.com/LT6CZo9.png',
    ],
    title: 'Showdown Clash  [ Web ]',
    titlePosition: 'top',
    titleMargin: '10px 0',
    description:
      'Página web de Showdown Clash: juego multijugador inspirado en New Super Mario Bros creado con Unity',
    descriptionPosition: 'bottom',
    descriptionMargin: '10px 0',
    imageStyle: { maxWidth: '50%', margin: '0 auto', objectFit: 'contain' },
    logoMargin: '15px 0',
    technologies: [
      {
        filter: 'HTML5',
        logo: 'https://img.shields.io/badge/HTML5-E34F26?style=for-the-badge&logo=html5&logoColor=white',
      },
      {
        filter: 'CSS3',
        logo: 'https://img.shields.io/badge/CSS-239120?&style=for-the-badge&logo=css3&logoColor=white',
      },
    ],
    buttons: [
      {
        text: 'Web',
        variant: 'secondary',
        icon: CgWebsite,
        link: 'https://showdownclash.vercel.app',
        newTab: true,
      },
      {
        text: 'Repositorio',
        variant: 'primary',
        icon: SiDiscord,
        link: 'https://github.com/ImPavloh/showdownclash',
        newTab: true,
      },
    ],
  },
  {
    types: ['repositorio', 'web'],
    opensource: true,
    showLogo: false,
    imgPaths: ['https://i.imgur.com/PzENOsA.png'],
    title: 'VoiceIt!',
    titlePosition: 'top',
    titleMargin: '15px 0',
    description:
      'Plataforma interactiva donde puedes subir un audio, seleccionar el modelo de voz del streamer que hay y, con un solo clic, transformar la voz original en la que elijas. ¡Obtén tu nuevo audio personalizado al instante!',
    descriptionPosition: 'bottom',
    descriptionMargin: '10px 0',
    imageStyle: { maxWidth: '100%', margin: '0 auto', objectFit: 'contain' },
    technologies: [
      {
        filter: 'Tensorflow',
        logo: 'https://img.shields.io/badge/TensorFlow-FF6F00?style=for-the-badge&logo=tensorflow&logoColor=white',
      },
      {
        filter: 'Python',
        logo: 'https://img.shields.io/badge/Python-14354C?style=for-the-badge&logo=python&logoColor=white',
      },
    ],
    buttons: [
      {
        text: 'Repositorio',
        variant: 'primary',
        icon: BsGithub,
        link: 'https://huggingface.co/spaces/ImPavloh/voiceit/tree/main',
        newTab: true,
      },
      {
        text: 'Demo',
        variant: 'secondary',
        icon: CgWebsite,
        link: '/voiceit',
        newTab: false,
      },
    ],
  },
  {
    types: ['repositorio'],
    opensource: true,
    showLogo: false,
    imgPaths: [
      'https://camo.githubusercontent.com/037776118f79d3f0e36edcb393113b58b9299f77659f237e99b7d026e5123c7b/68747470733a2f2f692e696d6775722e636f6d2f565670596562392e706e67',
    ],
    title: 'WhiTTsper The Lora',
    titleMargin: '10px 0',
    titlePosition: 'bottom',
    description:
      'Demo que combina Whisper para reconocimiento de voz y Google TTS para síntesis de voz para interactuar con el LLM Alpaca-LoRA. ~ Proyecto despriorizado',
    descriptionPosition: 'bottom',
    descriptionMargin: '0px 0',
    imageStyle: { maxWidth: '100%', margin: '0 auto', objectFit: 'contain' },
    technologies: [
      {
        filter: 'Python',
        logo: 'https://img.shields.io/badge/Python-14354C?style=for-the-badge&logo=python&logoColor=white',
      },
      {
        filter: 'Colab',
        logo: 'https://img.shields.io/badge/Colab-F9AB00?style=for-the-badge&logo=googlecolab&color=525252',
      },
    ],
    buttons: [
      {
        text: 'Repositorio',
        variant: 'primary',
        icon: BsGithub,
        link: 'https://github.com/ImPavloh/WhiTTsper-The-Lora',
        newTab: true,
      },
    ],
  },
  {
    types: ['repositorio'],
    opensource: true,
    showLogo: true,
    logoPath:
      'https://camo.githubusercontent.com/26b8d74dc192064444d15e03c6c20435ef6683583c00fe5baf866d2e615dfed9/68747470733a2f2f7777772e737069676f746d632e6f72672f646174612f7265736f757263655f69636f6e732f3130392f3130393331352e6a70673f31363831363736383833',
    imgPaths: [],
    title: 'Player Time Limit',
    titleMargin: '10px 0',
    titlePosition: 'bottom',
    description:
      'Plugin de Minecraft Spigot 1.19+ que limita el tiempo de juego del jugador.',
    descriptionPosition: 'bottom',
    descriptionMargin: '0px 0',
    imageStyle: { maxWidth: '100%', margin: '0 auto', objectFit: 'contain' },
    technologies: [
      {
        filter: 'Java',
        logo: 'https://img.shields.io/badge/Java-ED8B00?style=for-the-badge&logo=openjdk&logoColor=white',
      },
      { filter: 'Minecraft Plugin' },
    ],
    buttons: [
      {
        text: 'Repositorio',
        variant: 'primary',
        icon: BsGithub,
        link: 'https://github.com/ImPavloh/PlayerTimeLimit',
        newTab: true,
      },
      {
        text: 'Spigot',
        variant: 'secondary',
        icon: SiSpigotmc,
        link: 'https://www.spigotmc.org/resources/playertimelimit.109315/',
        newTab: true,
      },
    ],
  },
  {
    types: ['plataforma'],
    opensource: false,
    showLogo: true,
    logoPath: 'https://i.imgur.com/0HrbAHh.png',
    imgPaths: [
      'https://i.imgur.com/HhrO9BP.png',
      'https://i.imgur.com/50613ZY.png',
      'https://i.imgur.com/1EzzWoO.png',
    ],
    title: 'CurseForge Minecraft Mods',
    titleMargin: '10px 0',
    titlePosition: 'top',
    description:
      'Mods de Minecraft que he creado y están disponibles en CurseForge.',
    descriptionPosition: 'bottom',
    descriptionMargin: '10px 0',
    imageStyle: { maxWidth: '100%', margin: '0 auto', objectFit: 'contain' },
    technologies: [
      {
        filter: 'Java',
        logo: 'https://img.shields.io/badge/Java-ED8B00?style=for-the-badge&logo=openjdk&logoColor=white',
      },
      { filter: 'Minecraft Mod' },
    ],
    buttons: [
      {
        text: 'CurseForge',
        variant: 'secondary',
        icon: SiCondaforge,
        link: 'https://legacy.curseforge.com/members/pavloh/projects',
        newTab: true,
      },
    ],
  },
  {
    types: ['web'],
    opensource: false,
    showLogo: false,
    imgPaths: [
      'https://i.imgur.com/kmomF06.png',
      'https://i.imgur.com/TLM9pTP.png',
      'https://i.imgur.com/ebZ667L.png',
    ],
    title: 'Portfolio / Web personal',
    titleMargin: '10px 0',
    titlePosition: 'top',
    description: '¡La web en la que estás ahora mismo!',
    descriptionPosition: 'top',
    descriptionMargin: '10px 0',
    imageStyle: { maxWidth: '100%', margin: '0 auto', objectFit: 'contain' },
    technologies: [
      {
        filter: 'React',
        logo: 'https://img.shields.io/badge/React-20232A?style=for-the-badge&logo=react&logoColor=61DAFB',
      },
      {
        filter: 'Bootstrap',
        logo: 'https://img.shields.io/badge/Bootstrap-563D7C?style=for-the-badge&logo=bootstrap&logoColor=white',
      },
    ],
  },
]

function Projects() {
  const [selectedTypeFilter, setSelectedTypeFilter] = useState(null)
  const [selectedOpensourceFilter, setSelectedOpensourceFilter] = useState(null)
  const [selectedLanguageFilter, setSelectedLanguageFilter] = useState(null)
  const [filtersActive, setFiltersActive] = useState(false)

  const handleTypeFilterChange = (event) => {
    setSelectedTypeFilter(
      typeFilterOptions.find((option) => option.value === event.target.value),
    )
    setFiltersActive(true)
  }

  const handleLanguageFilterChange = (event) => {
    setSelectedLanguageFilter(
      languageFilterOptions.find(
        (option) => option.value === event.target.value,
      ),
    )
    setFiltersActive(true)
  }

  const handleOpensourceFilterChange = (event) => {
    const newValue = event.target.checked ? 'true' : 'false'
    setSelectedOpensourceFilter(
      opensourceFilterOptions.find((option) => option.value === newValue),
    )
    setFiltersActive(true)
  }

  const filteredProjects = projectData.filter((project) => {
    const typeFilter =
      !selectedTypeFilter ||
      (project.types && project.types.includes(selectedTypeFilter.value))
    const opensourceFilter =
      !selectedOpensourceFilter ||
      (selectedOpensourceFilter.value === 'true' && project.opensource) ||
      (selectedOpensourceFilter.value === 'false' && !project.opensource)
    const languageFilter =
      !selectedLanguageFilter ||
      (project.technologies &&
        project.technologies.some(
          (tech) => tech.filter === selectedLanguageFilter.value,
        ))
    return typeFilter && opensourceFilter && languageFilter
  })

  const resetFilters = () => {
    setSelectedTypeFilter(null)
    setSelectedOpensourceFilter(null)
    setSelectedLanguageFilter(null)
    setFiltersActive(false)
  }

  return (
    <Container fluid className="project-section">
      {!isMobile && (
        <AnimatedCursor
          innerSize={8}
          trailingSpeed={3}
          outerSize={20}
          outerAlpha={0}
          innerScale={0.7}
          outerScale={2}
          innerStyle={{
            boxShadow: '0px 0px 3px 2px rgba(0, 0, 0, 0.5)',
            backgroundColor: '#faedcd',
          }}
          outerStyle={{
            boxShadow: '0px 0px 5px 2px rgba(0, 0, 3, 0.5)',
            border: '3px solid #fefaf0',
          }}
          clickables={[
            'textarea',
            'button',
            '.link',
            'link',
            'btn',
            'a',
            '[role="button"]',
            'filter',
            'options',
            ' form-select',
          ]}
        />
      )}
      <Helmet>
        <title>Pavloh | Proyectos</title>
        <meta property="og:title" content="Proyectos" />
        <meta property="og:description" content="Proyectos de Pavloh" />
        <meta property="og:url" content="https://www.pavloh.com/proyectos" />
        <meta property="og:type" content="website" />
        <meta name="twitter:title" content="Proyectos" />
        <meta name="twitter:description" content="Proyectos de Pavloh" />
      </Helmet>
      <Particle />
      <Container>
        <h1 className="project-heading">
          Últimos <strong className="purple">proyectos </strong>
        </h1>
        <p style={{ color: 'white' }}>
          Algunos proyectos que he hecho recientemente.
        </p>
        <div className="filters">
          <div className="filter-item">
            <Form.Select
              style={{ width: 240, height: 40 }}
              className="Filter"
              onChange={handleTypeFilterChange}
            >
              {typeFilterOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </Form.Select>
            <Form.Select
              style={{ width: 240, height: 40 }}
              className="Filter"
              onChange={handleLanguageFilterChange}
            >
              {languageFilterOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </Form.Select>
            <Form.Check
              type="switch"
              id="opensource-switch"
              label={
                selectedOpensourceFilter &&
                selectedOpensourceFilter.value === 'true'
                  ? 'Código Público'
                  : 'Código Privado'
              }
              checked={
                selectedOpensourceFilter &&
                selectedOpensourceFilter.value === 'true'
              }
              onChange={handleOpensourceFilterChange}
            />
            <div style={{ height: '40px' }}>
              {filtersActive && (
                <button className="cbtn btn btn-danger" onClick={resetFilters}>
                  Borrar Filtros
                </button>
              )}
            </div>
          </div>
        </div>
        {filteredProjects.length === 0 ? (
          <Row style={{ justifyContent: 'center', paddingBottom: '10px' }}>
            <Col md={12} className="project-card">
              <div className="noProjects">
                <h2>
                  No se encontraron proyectos que coincidan con los filtros
                  seleccionados.
                </h2>
                <h3>Prueba con otros.</h3>
              </div>
            </Col>
          </Row>
        ) : (
          filteredProjects.map((project, index) => (
            <Row
              key={index}
              style={{ justifyContent: 'center', paddingBottom: '10px' }}
            >
              <Col md={12} className="project-card">
                <ProjectCard
                  showLogo={project.showLogo}
                  logoPath={project.logoPath}
                  imgPaths={project.imgPaths}
                  title={project.title}
                  titlePosition={project.titlePosition}
                  titleMargin={project.titleMargin}
                  description={project.description}
                  descriptionPosition={project.descriptionPosition}
                  descriptionMargin={project.descriptionMargin}
                  imageStyle={project.imageStyle}
                  logoMargin={project.logoMargin}
                  technologies={project.technologies}
                  buttons={project.buttons}
                />
              </Col>
            </Row>
          ))
        )}
      </Container>
    </Container>
  )
}

export default Projects
